var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Update',
    key: 'updateNewQuoteCountyValidation',
    disabled: _vm.disablePrimaryButton || !Object.keys(_vm.updatedFields).length,
    loading: _vm.makingApiRequest
  },"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem,"errorMessageClosed":function($event){_vm.errorMessage = ''},"successMessageClosed":function($event){_vm.successMessage = ''}}},[(!_vm.resourceNotFound)?[(_vm.$getCurrentUser && _vm.$getCurrentUser.role == 'admin')?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.menuItems,"selectedTabKey":_vm.selectedTabKey},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.showActivities)?_c('div',[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between items-center"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Quote Validation Details ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Edit the information about the quote validation. ")])]),_c('span',{class:[
            'px-4 py-1 flex items-center justify-center uppercase text-xs font-semibold text-white select-none ml-auto h-6',
            _vm.allowRating ? 'bg-green-400' : 'bg-red-400'
          ]},[_vm._v(" "+_vm._s(_vm.allowRating ? "ALLOW" : "BLOCK")+" ")])]),(_vm.editing)?_c('form-builder',{ref:"formElement",attrs:{"dynamicOptions":{
          companyNumbers: _vm.companyOptions,
          formTypes: _vm.formTypesOptions
        },"formValues":_vm.editing,"schemas":_vm.quoteCountyValidationsSchema,"formValueDefaults":_vm.formValueDefaults},on:{"formFieldChanged":_vm.formFieldChangedHandler,"validationChanged":_vm.validationChangeHandler}}):_vm._e(),(_vm.editing)?_c('div',{staticClass:"flex justify-start",class:[
          Object.keys(_vm.updatedFields).length > 0 ? 'justify-between' : ''
        ]}):_vm._e()],1):(_vm.showActivities)?_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}],null,false,3991501741)}):_vm._e()]:_c('ResourceNotFound',{attrs:{"title":"Resource not found.","subtitle":"Sorry, the quote validation you were looking for could not be found.","actionButtonTitle":"Back to quote validation","actionRedirectPath":"/admin/quoteCountyValidation"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }